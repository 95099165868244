
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import { ProductLogDetail } from '@/types/product.d'
@Component
@KeepAlive
export default class ProductLogList extends Vue {
  private searchInfo = {
    daterange: [],
    userName: '',
    recordType: ''
  }

  private loading = false
  private info: Array<ProductLogDetail> = []

  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    const info = this.searchInfo.daterange ? {
      startTime: this.searchInfo.daterange[0],
      endTime: this.searchInfo.daterange[1],
      userName: this.searchInfo.userName,
      recordType: this.searchInfo.recordType
    } : {
      userName: this.searchInfo.userName,
      recordType: this.searchInfo.recordType
    }
    this.$axios.get(this.$apis.product.selectProductRecordByPage, {
      page: this.page,
      size: this.size,
      ...info
    }).then((res) => {
      this.info = res.list || []
      this.total = res.total || 0
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 详情
  onDetail (id: string) {
    this.$router.push({
      name: 'productLogDetail',
      params: { id }
    })
  }
}
